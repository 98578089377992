import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { BirthdayForm } from "widgets/auth/forms";
import { useStore } from "zustand";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import VerificationStepsHeader from "../components/verification-steps-header";
import { toast } from "react-toastify";
import { accountStore, KYC_Step } from "entities/account";
import moment from "moment";

export const RegisterBirthdayPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("onboarding-auth");
  const { t: err } = useTranslation("errors");
  const { stepKyc, isLoading, kyc, synckyc } = useStore(accountStore);

  const onSubmit = async (birthDate: string) => {
    const resp = await stepKyc({
      step: KYC_Step.Birthday,
      birth_date: birthDate,
    });

    if (resp.error) {
      toast.error(err(String(resp.error.code)));
    } else {
      const kyc = resp.data.data;
      console.log(kyc);
      if (kyc) {
        synckyc(kyc);
        navigate(PATH_LIST.registerCitizenship);
      }
    }
  };

  return (
    <div className="register-birthday">
      <VerificationStepsHeader percent={28.5} step={2} />

      <div className="register-birthday-main">
        <p className="register-title">{t("whenIsYourBirthday")}</p>

        <BirthdayForm
          initialValues={
            kyc?.birth_date
              ? {
                  day: moment(kyc.birth_date, "YYYY-MM-DD").get("date"),
                  month: moment(kyc.birth_date, "YYYY-MM-DD").get("month"),
                  year: moment(kyc.birth_date, "YYYY-MM-DD").get("year"),
                }
              : { day: "", month: "", year: "" }
          }
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
