import React, { FC, useState } from "react";
import "./style.scss";
import { ProgressLine } from "widgets/progress-line";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GoBack from "../../../../assets/GoBackApp.svg";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { EntityType } from "static/types";

type PropsType = {
  percent: number;
  step: number;
  total?: number;
  hide?: boolean;
};

const VerificationStepsHeader: FC<PropsType> = ({
  percent,
  step,
  total,
  hide,
}) => {
  const navigate = useNavigate();
  const { t: a } = useTranslation("app");
  const { user, entity } = useStore(sessionModel.sessionStore);

  return (
    <div className="verification-steps-header">
      <img
        className="go-back"
        onClick={() => navigate(-1)}
        src={GoBack}
        alt="Go Back"
      />
      <p>{a("verification")}</p>

      {entity == EntityType.Individual && !hide && (
        <ProgressLine
          percent={percent}
          mobileInfo={`${step}/${total ? total : "7"}`}
          circle
        />
      )}
    </div>
  );
};

export default VerificationStepsHeader;
