import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import "./style.scss";
import RadioCard from "../components/radio-card";
import { useStore } from "zustand";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import VerificationStepsHeader from "../components/verification-steps-header";
import { accountStore, KYC_Step } from "entities/account";
import { toast } from "react-toastify";

export const RegisterGenderPage = () => {
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useStore(onboardModel.onboardStore);
  const { stepKyc, isLoading, kyc, synckyc } = useStore(accountStore);
  const [gender, setGender] = useState(kyc?.gender || "");
  const { t } = useTranslation("onboarding-auth");
  const { t: err } = useTranslation("errors");

  const onSubmit = async () => {
    const resp = await stepKyc({
      step: KYC_Step.Gender,
      gender,
    });

    if (resp.error) {
      toast.error(err(String(resp.error.code)));
    } else {
      const kyc = resp.data.data;
      console.log(kyc);
      if (kyc) {
        synckyc(kyc);
        navigate(PATH_LIST.registerBirthday);
      }
    }
  };

  return (
    <div className="register-gender">
      <VerificationStepsHeader percent={14.25} step={1} />

      <div className="register-gender-main">
        <p className="register-title">{t("whatsYourGender")}</p>

        <RadioCard
          text={t("male")}
          htmlForId="male"
          checked={gender === "male"}
          onClick={() => setGender("male")}
        />
        <RadioCard
          text={t("female")}
          htmlForId="female"
          checked={gender === "female"}
          onClick={() => setGender("female")}
        />

        <div className="btn-wrapper">
          <Button
            onClick={onSubmit}
            className="registerNameBtn"
            variant={"dark"}
            disabled={!gender}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};
