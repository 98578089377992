import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./RouterProvider";
import { ToastContainer } from "react-toastify";
import { Capacitor } from "@capacitor/core";

import { FullPageError } from "shared/ui/full-page-error";

import "react-toastify/dist/ReactToastify.css";

import AppUpdateModal from "widgets/check-update-modal";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "widgets/loading";

const appVersion = process.env.REACT_APP_VERSION || null;

export function Provider() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [appStoreUrl, setAppStoreUrl] = useState("");
  const [canSkip, setCanSkip] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkForUpdate = async () => {
    try {
      if (Capacitor.getPlatform() === "ios") {
        const response = await axios.get(
          "https://itunes.apple.com/lookup?bundleId=com.siriuscapital.siriuscapital",
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        const data = response.data;

        if (data.results && data.results.length > 0) {
          const latestVersion = data.results[0].version;
          const appStoreLink = data.results[0].trackViewUrl;
          console.log(appVersion, latestVersion);
          setAppStoreUrl(appStoreLink);

          if (appVersion) {
            if (
              latestVersion &&
              appVersion !== latestVersion &&
              latestVersion > appVersion
            ) {
              const currentMinor = appVersion.split(".").slice(0, 2).join(".");
              const latestMinor = latestVersion
                .split(".")
                .slice(0, 2)
                .join(".");
              setCanSkip(currentMinor === latestMinor);
              setModalVisible(true);
            }
          } else {
            setModalVisible(true);
          }
        }
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkForUpdate();
  }, []);

  return (
    <ErrorBoundary FallbackComponent={FullPageError}>
      <BrowserRouter>
        <ToastContainer
          hideProgressBar={false}
          closeButton={false}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          autoClose={3500}
          position={"top-right"}
        />
        {isLoading && <Loading />}
        {!isLoading && !isModalVisible && <Router />}
        {isModalVisible && (
          <AppUpdateModal
            canSkip={canSkip}
            onHide={() => setModalVisible(false)}
            onUpdate={() => window.open(appStoreUrl, "_blank")}
          />
        )}
      </BrowserRouter>
    </ErrorBoundary>
  );
}
