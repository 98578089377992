import { useCurrentUser } from "entities/session/model/sessionModel";
import { toast } from "react-toastify";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { OpenOrderDto } from "shared/api/sircap/Api";
import {
  AssetType,
  OperationStatus,
  OperationType,
  OrderType,
} from "static/types";
import { createStore, useStore } from "zustand";
import { persist } from "zustand/middleware";
import moment from "moment-timezone";
import { accountStore } from "entities/account";

export enum CouponType {
  Zero = "zero",
  Fixed = "fixed",
  Floating = "floating",
}
interface GetAssetsListDto {
  type?: string;
  sortBy?: string;
  orderBy?: string;
  page: number;
  limit: number;
}
export interface IAsset {
  id: number;
  symbol: string;
  name: string;
  type: number;
  ask: number;
  bid: number;
  currency: {
    code: string;
    symbol: string;
  };
  exchange: null | IExchange;
  data: any;
  lot_size: number;
  minimal_quantity: number;
  minimal_commission: number;
  change: number;
  changesPercentage: number;
  image: string;
}
export interface IAssetInfo {
  asset: IAsset | null;
  info: {
    symbol: string;
    mktCap: number;
    companyName: string;
    currency: string;
    isin: string;
    sector: string;
    industry: string;
    description: string;
    country: string;
    image: string;
    exchange: string;
  };
  bondInfo: {
    country: string;
    ask: number;
    bid: number;
    nominal: number;
    ai: number;
    ask_yield: number;
    bid_yield: number;
    coupon_rate: number;
    coupon_frequency: number | null;
    coupon_type: string;
    last_coupon_date: string | null;
    next_coupon_date: string | null;
    risk: number | null;
    bond_type: string | null;
    maturity_date: string;
    settlement_date: string;
    call_date: string | null;
    put_date: string | null;
    is_otc: boolean;
    is_eurobond: boolean;
    is_fractional: boolean;
  };
  decimal_places?: number;
  available_order_types?: OrderType[];
  tv_ticker?: string | null;
  customInfo?: {
    symbol: string;
    info: {
      card: string;
      background: string;
    };
  };
  dividends: {
    symbol: string;
    historical: Array<{
      date: string;
      label: string;
      adjDividend: number;
      dividend: number;
      recordDate: string;
      paymentDate: string;
      declarationDate: string;
    }>;
  };
  commission: number;
  minimal_commission: number;
}
type ChartType = Array<{
  date: string;
  open: number;
  low: number;
  high: number;
  close: number;
  volume: number;
}>;
interface IAssetChart {
  asset: IAsset | null;
  // changes: {
  //   symbol: string;
  //   "1D": number;
  // };
  chart: ChartType;
  weekChart?: ChartType;
  dayChart?: ChartType;
}
interface IStrategyInfo {
  asset: IAsset | null;
  chart: Array<{
    date: string;
    open: number;
    low: number;
    high: number;
    close: number;
    volume: number;
  }>;
  account: {
    currency: string;
    quantity: number;
    price: number;
    date: Date;
  } | null;
  description: string;
  customInfo: any;
}

export enum FilterType {
  Stock = "stock",
  Bond = "bond",
}
export interface IBondsFilterData {
  currency: null | string[];
  industry: null | string;
  is_eurobond: boolean;
  bond_type: null | string;
  yield_to_matutity: null | {
    from: number;
    to: number;
  };
  maturity_date: null | {
    from: string;
    to: string;
  };
  coupon: null | string;
  reliability_rating: null | number[];
  only_debt_amortization: boolean;
}
export interface IStocksFilterData {
  segment: null | string[];
  industry: null | string[];
  country: null | string[];
  dividend_yield: null | {
    from: number;
    to: number;
  };
  growth: boolean;
  value: boolean;
  market_cap: null | {
    from: number;
    to: number;
  };
  sort_by: string;
  order_by: string;
}
type FilterDataType =
  | { data: IBondsFilterData; type: FilterType.Bond }
  | { data: IStocksFilterData; type: FilterType.Stock };

export type IFilter = {
  id: number;
  user_id: number | null;
  user_type: string | null;
  name: string;
  changed?: boolean;
} & FilterDataType;

export type IFavorites = {
  id: number;
  asset: IAsset | null;
  user_id: number | null;
  user_type: string | null;
  name: string;
  changed?: boolean;
};

export interface IFilterDto {
  name: string;
  type: FilterType;
  data: IBondsFilterData | IStocksFilterData;
}

export interface IFavoritesDto {
  asset_id: number;
}

interface IDepthItem {
  price: number;
  quantity: number;
  operation: OperationType;
  progress: number;
}

export interface IExchange {
  id: number;
  name: string;
  symbol: string;
  pre_open: string | null;
  pre_close: string | null;
  open: string | null;
  close: string | null;
  after_open: string | null;
  after_close: string | null;
  timezone: string;
  logo: string;
  isHoliday: boolean;
}

export type OrderBy = "asc" | "desc";
type SortConfigs = Record<
  number,
  {
    sortBy: string;
    orderBy: OrderBy;
  }
>;
type MarketState = {
  sortConfigs: SortConfigs;
  setSortConfigs: (type: number, configs: any) => void;

  filterSortConfigs: null | {
    sortBy: string;
    orderBy: OrderBy;
  };
  setFilterSortConfigs: (configs: any) => void;

  isLoading: boolean;
  assets: any[];
  searchAssets: Record<number, any[]>;
  assetsCount: number;
  activeAssetType: number;
  setActiveAssetType: (type: number) => void;
  loadAssets: (query: GetAssetsListDto) => Promise<void>;

  searchHistory: string[];
  setSearchHistory: (v: string) => void;
  clearSearchHistory: () => void;

  assetInfo: IAssetInfo | null;
  assetChart: IAssetChart | null;
  assetDeals: any[];
  assetDepth: IDepthItem[];
  tariffs: any[];
  exchanges: IExchange[];
  search: (q: string) => void;
  clearSearchAssets: () => void;
  loadAssetDeals: (id: number) => void;
  newAssetDeal: (deal: any) => void;
  updateAssetDeal: (deal: any) => void;
  loadAssetInfo: (asset_id: any) => void;
  loadAssetDepth: (asset_id: any) => void;
  loadAssetChart: (asset_id: any) => void;

  getAssetRealTimePrice: (symbol: string) => void;
  setAssetRealTimePrice: (symbol: string, quote: any) => void;
  checkCurrencyAsset: () => void;
  cancelDeal: (id: number) => void;
  openOrder: (id: number, data: OpenOrderDto, t: any, cb: any) => void;
  clearDeals: Function;
  loadTariffs: Function;
  loadExchanges: () => Promise<void>;

  strategies: any[];
  strategy: IStrategyInfo | null;
  getStrategies: () => Promise<void>;
  getStrategy: (symbol: string) => void;

  filters: IFilter[];
  createdFilter: Partial<IFilter> | null;
  loadFilters: () => Promise<void>;
  createFilter: (body: IFilterDto) => Promise<any>;
  updateFilter: (id: number, body: IFilterDto) => Promise<any>;
  removeFilter: (id: number) => Promise<any>;

  favorites: IFavorites[];
  loadFavorites: () => Promise<void>;
  addFavorites: (body: IFavoritesDto) => Promise<any>;
  removeFavorite: (id: number) => Promise<any>;

  filterAssets: any[];
  filterAssetsCount: number;
  loadAssetsByFilters: (query: GetAssetsListDto) => Promise<void>;
  preloadAssetsByFilters: (signal: AbortSignal) => Promise<void>;
  loadBondsByFilters: (query: GetAssetsListDto) => Promise<void>;
  preloadBondsByFilters: (signal: AbortSignal) => Promise<void>;

  // load filter fields
  filterStockIndustries: () => Promise<void>;
  stockIndustries: any[];
  filterSectors: () => Promise<void>;
  sectors: any[];
  filterCountries: () => Promise<void>;
  countries: string[];
};

export const marketStore = createStore<MarketState>()(
  persist(
    (set, get) => ({
      sortConfigs: {},
      setSortConfigs: (type: number, configs: any) => {
        set({
          sortConfigs: {
            ...get().sortConfigs,
            [type]: configs,
          },
          assets: [],
          assetsCount: 0,
        });
      },

      filterSortConfigs: null,
      setFilterSortConfigs: (v) =>
        set({ filterSortConfigs: v, filterAssets: [], filterAssetsCount: 0 }),

      assetInfo: null,
      assetChart: null,
      isLoading: false,
      assets: [],
      assetsCount: 0,
      filterAssetsCount: 0,
      searchAssets: {},
      activeAssetType: 1,
      assetDeals: [],
      assetDepth: [],
      tariffs: [],
      exchanges: [],
      stockIndustries: [],
      sectors: [],
      filterAssets: [],
      loadExchanges: async () => {
        try {
          const resp = await sircapApi.dashboard.exchanges();

          if (resp.error) {
          } else {
            set({ exchanges: resp.data.data });
          }
        } catch (error) {}
      },
      setActiveAssetType: (type: number) => {
        set({ activeAssetType: type, assets: [], assetsCount: 0 });
      },
      loadAssets: async (query: GetAssetsListDto) => {
        try {
          const isNewTab = query.page === 0;
          set({ isLoading: isNewTab });
          const resp = await sircapApi.market.assets({ params: query });

          if (resp.error) {
          } else {
            const [list, count] = resp.data.data;
            if (isNewTab) {
              set({ assets: list, assetsCount: count });
            } else {
              const lastAssets = get().assets;
              set({ assets: [...lastAssets, ...list], assetsCount: count });
            }
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      loadAssetsByFilters: async (query: GetAssetsListDto) => {
        try {
          const isNewTab = query.page === 0;
          set({ isLoading: isNewTab });
          const filter = get().createdFilter?.data || {};
          const resp = await sircapApi.market.getAssetsByFilters(filter, {
            params: query,
          });

          if (resp.error) {
          } else {
            const [list, count] = resp.data.data;
            if (isNewTab) {
              set({ filterAssets: list, filterAssetsCount: count });
            } else {
              const lastAssets = get().filterAssets;
              set({
                filterAssets: [...lastAssets, ...list],
                filterAssetsCount: count,
              });
            }
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      preloadAssetsByFilters: async (signal: AbortSignal) => {
        try {
          set({ isLoading: true });
          const filter = get().createdFilter?.data || {};
          const resp = await sircapApi.market.getAssetsByFilters(filter, {
            signal,
            params: {
              page: 0,
              limit: 1,
            },
          });

          if (!signal.aborted) {
            if (resp.error) {
            } else {
              const [list, count] = resp.data.data;
              set({ filterAssetsCount: count });
            }
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      loadBondsByFilters: async (query: GetAssetsListDto) => {
        try {
          const isNewTab = query.page === 0;
          set({ isLoading: isNewTab });
          const filter = get().createdFilter?.data || {};
          const resp = await sircapApi.market.getBondsByFilters(filter, {
            params: query,
          });

          if (resp.error) {
          } else {
            const [list, count] = resp.data.data;
            if (isNewTab) {
              set({ filterAssets: list, filterAssetsCount: count });
            } else {
              const lastAssets = get().filterAssets;
              set({
                filterAssets: [...lastAssets, ...list],
                filterAssetsCount: count,
              });
            }
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      preloadBondsByFilters: async (signal: AbortSignal) => {
        try {
          set({ isLoading: true });
          const filter = get().createdFilter?.data || {};
          const resp = await sircapApi.market.getBondsByFilters(filter, {
            signal,
            params: {
              page: 0,
              limit: 1,
            },
          });

          if (!signal.aborted) {
            if (resp.error) {
            } else {
              const [list, count] = resp.data.data;
              set({ filterAssetsCount: count });
            }
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      search: async (query: string) => {
        set({ isLoading: true });
        try {
          const resp = await sircapApi.market.search(query);
          if (resp.error) {
          } else {
            const list = resp.data.data;
            const searchAssets: Record<number, any[]> = {};
            for (const item of list) {
              const type = item.type;
              if (!(type in searchAssets)) {
                searchAssets[type] = [item];
              } else {
                searchAssets[type].push(item);
              }
            }
            set({ searchAssets });
          }
        } catch (error) {}
        set({ isLoading: false });
      },
      clearSearchAssets: () => {
        set({ searchAssets: [] });
      },
      loadAssetInfo: async (asset_id: any) => {
        set({ isLoading: true, assetDeals: [] });
        const resp = await sircapApi.market.assetInfo(asset_id)();

        if (resp.error) {
        } else {
          const asset = resp.data.data;
          set({ assetInfo: asset });
        }
        set({ isLoading: false });
      },
      loadAssetDepth: async (asset_id: any) => {
        try {
          // set({ assetDepth: [] });
          const resp = await sircapApi.market.assetDepth(asset_id)({
            timeout: 4000,
          });

          if (resp.error) {
          } else {
            const data = resp.data.data;
            set({ assetDepth: data });
          }
        } catch (error) {}
      },

      searchHistory: [],
      setSearchHistory: (v: string) => {
        const newHistory = [v, ...get().searchHistory.filter((i) => i !== v)];
        if (newHistory.length > 12) {
          newHistory.pop();
        }
        set({ searchHistory: newHistory });
      },
      clearSearchHistory: () => {
        set({ searchHistory: [] });
      },

      getAssetRealTimePrice: async (symbol: string) => {
        try {
          const resp = await sircapApi.market.assetPrice(symbol)({
            timeout: 5 * 1000,
          });

          if (resp.error) {
            return null;
          } else {
            const data = resp.data.data;
            const oldAsset = get().assetInfo;
            if (oldAsset && oldAsset.asset) {
              set({
                assetInfo: {
                  ...oldAsset,
                  asset: {
                    ...oldAsset.asset,
                    ask: data.askPrice,
                    bid: data.bidPrice,
                  },
                },
              });
            }
          }
        } catch (error) {
          return null;
        }
      },

      setAssetRealTimePrice: (symbol: string, quote: any) => {
        const oldAsset = get().assetInfo;
        const stock = symbol.includes("~") ? symbol.split("~")[1] : symbol;
        const exchange = symbol.includes("~") ? symbol.split("~")[0] : null;
        if (oldAsset && oldAsset.asset && oldAsset.asset?.symbol === stock) {
          if (
            oldAsset.asset?.exchange &&
            oldAsset.asset?.exchange?.symbol === exchange
          ) {
            set({
              assetInfo: {
                ...oldAsset,
                asset: {
                  ...oldAsset.asset,
                  ask: quote.askPrice,
                  bid: quote.bidPrice,
                },
              },
            });
          }
        }
      },

      checkCurrencyAsset: () => {
        const oldAsset = get().assetInfo;
        if (
          oldAsset &&
          oldAsset.asset &&
          oldAsset.asset.type === AssetType.Currency
        ) {
          get().loadAssetInfo(oldAsset.asset.id);
        }
      },

      loadAssetDeals: async (id: number) => {
        // set({ isLoading: true });
        try {
          const resp = await sircapApi.market.assetDeals(id)({
            timeout: 5 * 1000,
          });

          if (resp.error) {
          } else {
            set({ assetDeals: resp.data.data });
          }
        } catch (error) {}
        // set({ isLoading: false });
      },
      newAssetDeal: (deal: any) => {
        set({ assetDeals: [deal, ...get().assetDeals] });

        if (
          [
            OperationStatus.Actively,
            OperationStatus.OnPerformance,
            OperationStatus.CancellationRequest,
          ].includes(deal.status)
        ) {
          accountStore.setState({
            activeTransactions: [
              deal,
              ...accountStore.getState().activeTransactions,
            ],
          });
        } else {
          accountStore.setState({
            transactions: [deal, ...accountStore.getState().transactions],
          });
        }
      },
      updateAssetDeal: (deal: any) => {
        if (
          [
            OperationStatus.Error,
            OperationStatus.Error,
            OperationStatus.NotExecuted,
            OperationStatus.Refusal,
          ].includes(deal.status)
        ) {
          toast.warning(`Order #${deal.id} is rejected!`);
        } else if (
          [OperationStatus.NotExecuted, OperationStatus.Expired].includes(
            deal.status
          )
        ) {
          toast.warning(`Order #${deal.id} is expired!`);
        } else if (deal.status === OperationStatus.Canceled) {
          toast.info(`Order #${deal.id} is cancelled!`);
        } else if (deal.status === OperationStatus.Completed) {
          toast.success(`Order #${deal.id} is completed!`);
        } else if (deal.status === OperationStatus.PartiallyCompleted) {
          toast.success(`Order #${deal.id} is partially completed!`);
        }

        if (
          [
            OperationStatus.Actively,
            OperationStatus.OnPerformance,
            OperationStatus.CancellationRequest,
          ].includes(deal.status)
        ) {
          set({
            assetDeals: get().assetDeals.map((d) =>
              d.id === deal.id ? deal : d
            ),
          });

          accountStore.setState({
            activeTransactions: accountStore
              .getState()
              .activeTransactions.map((t) => (t.id === deal.id ? deal : t)),
          });
        } else {
          set({
            assetDeals: get().assetDeals.filter((d) => d.id !== deal.id),
          });

          const currTransactions = accountStore.getState().transactions;
          accountStore.setState({
            transactions: currTransactions.find((t) => t.id === deal.id)
              ? currTransactions.map((t) => (t.id === deal.id ? deal : t))
              : [deal, ...currTransactions],
            activeTransactions: accountStore
              .getState()
              .activeTransactions.filter((t) => t.id !== deal.id),
          });
        }
      },

      loadAssetChart: async (asset_id: any) => {
        set({ isLoading: true });
        const resp = await sircapApi.market.assetChart(asset_id)();

        if (resp.error) {
        } else {
          const asset = resp.data.data;
          set({ assetChart: asset });
        }
        set({ isLoading: false });
      },

      cancelDeal: async (id: number) => {
        set({ isLoading: true });
        const resp = await sircapApi.market.cancelDeal(id)();

        if (resp.error) {
          toast.error("Server Error");
        } else {
          toast.info("Please wait until we cancel your order");
          set({ assetDeals: resp.data.data });
        }
        set({ isLoading: false });
      },

      openOrder: async (id: number, data: OpenOrderDto, t: any, cb: any) => {
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.market.createDeal(id)(data);

          if (resp.error) {
            cb(resp.error.code || "500");
            rej();
          } else {
            get().loadAssetDeals(id);
            cb();
            res();
          }
        });

        toast.promise(promise, {
          pending: "Pending",
        });
      },

      clearDeals: () => {
        set({ assetDeals: [] });
      },

      loadTariffs: async () => {
        const resp = await sircapApi.tariffs();
        if (resp.error) {
        } else {
          set({ tariffs: resp.data.data });
        }
      },

      strategies: [],
      strategy: null,
      getStrategies: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.market.strategies({});

          if (resp.error) {
          } else {
            const list = resp.data.data;
            set({ strategies: list });
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      getStrategy: async (symbol: string) => {
        try {
          set({ isLoading: true, assetDeals: [] });
          const resp = await sircapApi.market.getStrategy(symbol)();

          if (resp.error) {
          } else {
            const asset = resp.data.data;
            set({ strategy: asset });
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },

      filters: [],
      createdFilter: null,
      loadFilters: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.market.loadFilters();
          if (resp.error) {
            toast.error("Server Error");
          } else {
            let filters = resp.data.data;
            set({ filters });
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      createFilter: async (body: IFilterDto) => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.market.createFilter(body);
          if (resp.error) {
            toast.error("Server Error");
          } else {
            toast.success("Success");
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      updateFilter: async (id: number, body: IFilterDto) => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.market.updateFilter(id)(body);
          if (resp.error) {
            toast.error("Server Error");
          } else {
            toast.success("Success");
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      removeFilter: async (id: number) => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.market.deleteFilter(id)();
          await get().loadFilters();
          if (resp.error) {
            toast.error("Server Error");
          } else {
            toast.success("Success");
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      filterStockIndustries: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.market.filterStockIndustries();
          if (resp.error) {
            toast.error("Server Error");
          } else {
            let stockIndustries = resp.data.data;
            set({ stockIndustries });
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      filterSectors: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.market.filterSectors();
          if (resp.error) {
            toast.error("Server Error");
          } else {
            let sectors = resp.data.data;
            set({ sectors });
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      countries: [],
      filterCountries: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.market.filterCountries();
          if (resp.error) {
            toast.error("Server Error");
          } else {
            let countries = resp.data.data;
            set({ countries });
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },

      favorites: [],
      loadFavorites: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.whatToBuy.loadFavorites();
          if (resp.error) {
            toast.error("Server Error");
          } else {
            let favorites = resp.data.data;
            set({ favorites });
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      addFavorites: async (body: IFavoritesDto) => {
        try {
          // set({ isLoading: true });
          const resp = await sircapApi.whatToBuy.addFavorites(body);
          await get().loadFavorites();
        } catch (error) {
          toast.error("Server Error");
        } finally {
          // set({ isLoading: false });
        }
      },
      removeFavorite: async (id: number) => {
        try {
          // set({ isLoading: true });
          const resp = await sircapApi.whatToBuy.removeFavorite(id)();
          await get().loadFavorites();
        } catch (error) {
          toast.error("Server Error");
        } finally {
          // set({ isLoading: false });
        }
      },
    }),

    {
      name: "market",
      version: 3,
      merge: (persistedState, currState) => {
        const {
          sortConfigs,
          activeAssetType,
          tariffs,
          searchHistory,
          createdFilter,
        } = persistedState as Partial<MarketState>;

        return {
          ...currState,
          sortConfigs: sortConfigs || {},
          activeAssetType: activeAssetType || 1,
          tariffs: tariffs?.length ? tariffs : [],
          searchHistory: searchHistory?.length ? searchHistory : [],
          createdFilter: createdFilter || null,
        };
      },
    }
  )
);

export const useSortConfigs = () =>
  useStore(marketStore, (state) => state.sortConfigs);

export const useTariff = () => {
  const user = useCurrentUser();
  const { tariffs } = useStore(marketStore);

  try {
    return tariffs.find(({ id }) => id === user?.tariff);
  } catch (error) {
    return null;
  }
};

export const useIsExchangeActive = (ex: number | undefined) => {
  try {
    // const IS_DEV_MODE = process.env.REACT_APP_DEV_MODE === "true";
    // if (IS_DEV_MODE) return true;
    // if day is weekend all exchanges are closed
    const weekday = moment().format("dddd");
    const isWeekend = ["Sunday", "Saturday"].includes(weekday);
    if (isWeekend) return false;

    const exchange = marketStore.getState().exchanges.find((e) => e.id === ex);
    if (!exchange) return false;

    if (!exchange.open || !exchange.close || !exchange.timezone) {
      return false;
    }

    const { open, close, timezone } = exchange;

    // moment.tz.setDefault(timeZone);
    const startTime = moment(open, "HH:mm").tz(timezone, true);
    const endTime = moment(close, "HH:mm").tz(timezone, true);

    const nowTime = moment() // moment("00:30", "HH:mm").tz("Asia/Yerevan", true)
      .tz(timezone)
      .set("date", startTime.get("date"));

    // console.log("------");
    // console.log("Start:", startTime.toISOString(true));
    // console.log("End:", endTime.toISOString(true));
    // console.log("Now:", nowTime.toISOString(true));
    if (nowTime.isBetween(startTime, endTime)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const useSelectFilters = (
  type: FilterType,
  author: "user" | "admin"
) => {
  const { filters } = useStore(marketStore);

  return filters
    .filter((f) => f.type === type)
    .filter((f) =>
      author === "admin" ? f.user_id === null : f.user_id !== null
    );
};

// filter
