import { sessionStore } from "entities/session/model/sessionModel";
import { MultiLangField } from "helpers/functions";
import { toast } from "react-toastify";
import { ContentType } from "shared/api/http-client";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import {
  ChangePasswordDto,
  ChangePinCodeDto,
  ResetPinCodeDto,
  GenerateReportDto,
  RegisterEmailDto,
  RegisterPhoneDto,
  WithdrawRequestDto,
  DepositRequestDto,
} from "shared/api/sircap/Api";
import { EntityType } from "static/types";
import { createStore, useStore } from "zustand";
import { persist } from "zustand/middleware";

export enum KYC_Status {
  None = 0,
  Pending = 1,
  Rejected = 2,
  Success = 3,
}

export enum KYC_Step {
  None = 0,
  Gender = 2,
  Birthday = 4,
  Citizenship = 6,
  Residence = 7,
  Passport = 8,
  ProofOfAddress = 10,
  Selfie = 12,
  UtilityBill = 14,
  Address = 16,
}

interface IKycInfo {
  user_id: number;
  step: KYC_Step;
  status: KYC_Status;
  reject_reason: string;
  is_armenia_resident: boolean;
  citizenship: string;
  gender: string;
  birth_date: string;
  personal_address: string;
  social_number: string;
  national_id: string;
  residence: string;
  has_american_citizenship: boolean;
}

export enum BonusType {
  Verification = "verification",
  Questionnaire = "questionnaire",
  Other = "other",
}

interface IBonusItem {
  id: number;
  points: number;
  type: BonusType;
  timer_hours: number | null;
  startTimer: string | null;
  active: boolean;
  activated: boolean;
  expired: boolean;
  automatically: boolean;
  manually: boolean;
  title: MultiLangField;
  description: MultiLangField;
}

interface IQuestion {
  alias: string;
  text: MultiLangField;
  type: "select" | "multi-select" | "text";
  options: Array<{ label: MultiLangField; value: string }>;
  info: MultiLangField;
}

interface IKybInfo {
  user_id: number;
  step: KYC_Step;
  status: KYC_Status;
  reject_reason: string;
  documents: any;
  additional_document: any;
  citizenship: string;
  residence?: string;
  personal_address: string;
  tin: string;
  yur_name: string;
  national_id: string;
}

interface IDepositInfo {
  id: number;
  currency: { code: string };
  accountNumber: string;
  recipient: string;
  info_source: any;
}

interface IWithdrawInfo {
  id: number;
  user: number;
  entity_type: string;
  amount: number;
  currency: { code: string; symbol: string };
  account_number: string;
  info_source: any;
}

interface IActivity {
  id: number;
  t: "deposit" | "withdrawal" | "payment";
  type: "deposit" | "withdrawal" | "commission" | "payment";
  status: number;
  amount: number;
  currency: string;
  createdAt: string;
  comment: string;
  asset: {
    symbol: string;
  };
}

interface ITransaction {}

interface IReport {
  from_date: string;
  to_date: string;
  filename: string;
  type: string;
  user_identificator: string;
}

export interface KycStartDto {
  is_armenia_resident: boolean;
}

export interface KycSteptDto {
  step: number;
  gender?: string;
  birth_date?: string;
  citizenship?: string;
  residence?: string;
  personal_address?: string;
  document_number?: string;
  has_american_citizenship?: boolean;
}

type AccountState = {
  isLoading: boolean;
  requestedEmail: string;
  requestedPhone: string;
  depositInfo: IDepositInfo[];
  withdrawInfo: IWithdrawInfo[];
  depositCurrencies: { code: string }[];
  selectedDepositCurrency: string;
  selectedWithdrawCurrency: string;

  uploadAvatar: (file: File, t: any, cb: any) => void;
  removeAvatar: (t: any, cb: any) => void;
  changePassword: (body: ChangePasswordDto, t: any, cb: any) => void;
  changePinCode: (body: ChangePinCodeDto, t: any, cb: any) => void;
  resetPinCode: (body: ResetPinCodeDto) => Promise<null | any>;

  requestChangeEmail: (
    body: Partial<RegisterEmailDto>,
    t: any,
    cb: any
  ) => void;
  confirmChangeEmail: (body: any, t: any, cb: any, catchCb: any) => void;
  requestChangePhone: (
    body: Partial<RegisterPhoneDto>,
    t: any,
    cb: any
  ) => void;
  confirmChangePhone: (body: any, t: any, cb: any, catchCb: any) => void;

  getDepositInfo: () => void;
  loadVerification: () => any;
  getWithdrawInfo: () => void;
  requestWithdrawal: (data: WithdrawRequestDto, cb: any) => void;
  requestDeposit: (data: DepositRequestDto, cb: any) => void;
  cancelWithdrawal: (id: number, cb: any) => void;
  selectCurrency: (type: "deposit" | "withdraw") => (curr: string) => void;
  getContract: () => Promise<any>;
  requestDataDeletion: (t: any, pinCode: string) => Promise<any>;
  signContract: (
    file: File,
    pinCode: string,
    width: number,
    height: number,
    t: any,
    onSuccess: any
  ) => Promise<any>;

  activites: IActivity[];
  activitesCount: number;
  transactions: any[];
  transactionsCount: number;

  activeActivites: IActivity[];
  activeActivitesCount: number;
  activeTransactions: any[];
  activeTransactionsCount: number;
  getActivities: (page: number, limit: number) => void;
  getTransactions: (page: number, limit: number) => void;
  getActiveTransactions: (page: number, limit: number) => void;

  reports: IReport[];
  getReports: () => Promise<void>;
  generateReport: (dto: GenerateReportDto) => Promise<string>;

  promoCode: (value: string) => Promise<any>;

  kyc: IKycInfo | null;
  kyb: IKybInfo | null;

  synckyc: (kyc: IKycInfo) => void;
  synckyb: (kyb: IKybInfo) => void;

  startKyc: (dto: KycStartDto) => any;

  startKyb: (dto: KycStartDto) => any;
  stepKyc: (dto: KycSteptDto) => any;

  questionnaireStatus: KYC_Status | null;
  questionnaire: {
    questionnaire: Array<IQuestion>;
    status: KYC_Status;
    answers: any;
  } | null;
  loadQuestionnaire: () => void;
  submitQuestionnaire: (data: any) => void;

  bonusInfo: {
    balance: number;
    tasks: IBonusItem[];
  } | null;

  getBonusInfo: () => Promise<void>;
  activateBonus: (id: number, err: any) => Promise<void>;
};

export const accountStore = createStore<AccountState>()(
  persist(
    (set, get) => ({
      isLoading: false,
      requestedEmail: "",
      requestedPhone: "",
      depositInfo: [],
      depositCurrencies: [],
      selectedDepositCurrency: "",
      selectedWithdrawCurrency: "",
      withdrawInfo: [],

      uploadAvatar: async (file: File, t: any, cb: any) => {
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.account.uploadAvatar(
            { avatar: file },
            { type: ContentType.FormData }
          );

          if (resp.error) {
            // switch (resp.error.code) {
            //   case ResponseCodes.ServerError:
            //     toast.error(t(String(resp.error.code)));
            //     break;

            //   default:
            //     break;
            // }
            rej();
          } else {
            cb(resp.data.data);
            res();
          }
        });

        toast.promise(promise, {
          pending: "Uploading",
          error: "Server Error",
          success: "Success",
        });
      },
      removeAvatar: async (t: any, cb: any) => {
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.account.removeAvatar();

          if (resp.error) {
            rej();
          } else {
            cb(resp.data.data);
            res();
          }
        });

        toast.promise(promise, {
          pending: "Deleting",
          error: "Server Error",
          success: "Success",
        });
      },
      changePassword: async (body: ChangePasswordDto, t: any, cb: any) => {
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.account.changePass(body);

          if (resp.error) {
            rej();
          } else {
            cb();
            res();
          }
        });

        toast.promise(promise, {
          pending: "Pending",
          error: "Server Error",
        });
      },
      changePinCode: async (body: ChangePinCodeDto, t: any, cb: any) => {
        set({ isLoading: true });
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.account.changePinCode(body);

          set({ isLoading: false });
          if (resp.error) {
            rej();
          } else {
            cb(true);
            res();
          }
        });

        toast.promise(promise, {
          pending: "Pending",
          error: "Wrong code",
        });
      },

      resetPinCode: async (body: ResetPinCodeDto) => {
        set({ isLoading: true });
        try {
          const resp = await sircapApi.account.resetPinCode(body);

          set({ isLoading: false });
          if (resp.error) {
            return null;
          } else {
            return resp.data.data?.pin || null;
          }
        } catch (error) {
          return null;
        }
      },
      getContract: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.getContract();
          return resp;
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      signContract: async (
        file: File,
        pinCode: string,
        width: number,
        height: number,
        t: any,
        onSuccess: any
      ) => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.signContract(
            { sign: file, pinCode, signWidth: width, signHeight: height },
            { type: ContentType.FormData }
          );
          if (resp.error) {
            toast.error(t(resp.error.code));
          } else {
            onSuccess();
            toast.success("Success");
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      requestDataDeletion: async (t: any, pinCode: string) => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.requestDataDeletion(
            {},
            {
              params: {
                pinCode,
              },
            }
          );
          if (resp.error) {
            toast.error(t(resp.error.code));
          } else {
            toast.success("Success");
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          set({ isLoading: false });
        }
      },
      requestChangeEmail: (
        body: Partial<RegisterEmailDto>,
        t: any,
        cb: any
      ) => {
        set({ isLoading: true });
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.account.requestChangeEmail(body);

          set({ isLoading: false });
          if (resp.error) {
            rej();
          } else {
            set({ requestedEmail: body.email });
            cb();
            res();
          }
        });

        toast.promise(promise, {
          pending: "Pending",
          error: "Server Error",
        });
      },
      confirmChangeEmail: (
        body: { code: number },
        t: any,
        cb: any,
        catchCb: any
      ) => {
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.account.confirmChangeEmail(body);

          if (resp.error) {
            catchCb();
            rej();
          } else {
            cb();
            res();
          }
        });

        toast.promise(promise, {
          pending: "Pending",
          error: "Server Error",
        });
      },
      requestChangePhone: (
        body: Partial<RegisterPhoneDto>,
        t: any,
        cb: any
      ) => {
        set({ isLoading: true });
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.account.requestChangePhone(body);

          set({ isLoading: false });
          if (resp.error) {
            rej();
          } else {
            set({ requestedPhone: body.phone });
            cb();
            res();
          }
        });

        toast.promise(promise, {
          pending: "Pending",
          error: "Server Error",
        });
      },
      confirmChangePhone: (
        body: { code: number },
        t: any,
        cb: any,
        catchCb: any
      ) => {
        const promise = new Promise<void>(async (res, rej) => {
          const resp = await sircapApi.account.confirmChangePhone(body);

          if (resp.error) {
            catchCb();
            rej();
          } else {
            cb();
            res();
          }
        });

        toast.promise(promise, {
          pending: "Pending",
          error: "Server Error",
        });
      },

      getDepositInfo: async () => {
        set({ isLoading: true });
        const resp = await sircapApi.transfer.depositInfo();

        if (resp.error) {
        } else {
          const data = resp.data.data;
          set({ depositInfo: data });
          try {
            const currencies: any[] = data.map((v: any) => v.currency);
            set({ depositCurrencies: currencies });
            const selected = get().selectedDepositCurrency;
            if (selected) {
              if (!(data as Array<any>).find((v) => v.code === selected)) {
                set({ selectedDepositCurrency: currencies[0].code });
              }
            } else {
              set({ selectedDepositCurrency: currencies[0].code });
            }
          } catch (error) {}
        }
        set({ isLoading: false });
      },
      loadVerification: async () => {
        // set({isLoading: true});
        const resp = await sircapApi.onboarding.verificationLoad();

        if (resp.error) {
          return null;
        } else {
          const data = resp.data.data;
          console.log("====================================");
          console.log(data);
          console.log("====================================");
          return data;
        }
        // set({isLoading: false});
      },
      getWithdrawInfo: async () => {
        set({ isLoading: true });
        try {
          const resp = await sircapApi.transfer.withdrawInfo();

          if (resp.error) {
          } else {
            const data = resp.data.data;
            set({ withdrawInfo: data });
            if (data) {
              const currencies: any[] = data.map((v: any) => v.currency.code);
              const selected = get().selectedWithdrawCurrency;
              if (selected) {
                if (!currencies.includes(selected)) {
                  set({ selectedWithdrawCurrency: currencies[0] });
                }
              } else {
                set({ selectedWithdrawCurrency: currencies[0] });
              }
            }
          }
        } catch (error) {}
        set({ isLoading: false });
      },
      requestWithdrawal: async (data: WithdrawRequestDto, cb: any) => {
        set({ isLoading: true });
        const resp = await sircapApi.transfer.withdrawRequest(data);

        cb(resp);
        set({ isLoading: false });
      },
      requestDeposit: async (data: DepositRequestDto, cb: any) => {
        set({ isLoading: true });
        const resp = await sircapApi.transfer.depositRequest(data);

        cb(resp);
        set({ isLoading: false });
      },
      cancelWithdrawal: async (id: number, cb: any) => {
        set({ isLoading: true });
        const resp = await sircapApi.transfer.withdrawCancel({ id });

        if (resp.error) {
          toast.error("Server Error");
        } else {
          cb(resp.data);
        }
        set({ isLoading: false });
      },
      selectCurrency: (type: "deposit" | "withdraw") => (curr: string) => {
        if (type === "deposit") {
          set({ selectedDepositCurrency: curr });
        } else {
          set({ selectedWithdrawCurrency: curr });
        }
      },

      startKyc: async (dto: KycStartDto) => {
        set({ isLoading: true });
        try {
          const resp = await sircapApi.account.startKyc(dto);
          set({ isLoading: false });
          return resp;
        } catch (error) {
          set({ isLoading: false });
          toast.error("Server Error");
        }
      },
      startKyb: async (dto: KycStartDto) => {
        set({ isLoading: true });
        try {
          const resp = await sircapApi.account.startKyb(dto);
          set({ isLoading: false });
          return resp;
        } catch (error) {
          set({ isLoading: false });
          toast.error("Server Error");
        }
      },

      stepKyc: async (dto: KycSteptDto) => {
        set({ isLoading: true });
        try {
          const resp = await sircapApi.account.stepKyc(dto);
          set({ isLoading: false });
          return resp;
        } catch (error) {
          set({ isLoading: false });
          toast.error("Server Error");
        }
      },

      kyc: null,
      kyb: null,

      synckyc: (kyc: IKycInfo) => {
        set({ kyc });
      },
      synckyb: (kyb: IKybInfo) => {
        const isLoad = get().isLoading;
        set({ isLoading: true });
        set({ kyb });
        set({ isLoading: isLoad });
      },

      questionnaireStatus: null,
      questionnaire: null,

      loadQuestionnaire: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.questionLoad({});
          if (resp.error) {
          } else {
            const data = resp.data.data;
            set({ questionnaire: data });
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      submitQuestionnaire: async (data: any) => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.questionSubmit(data);
          if (resp.error) {
          } else {
            toast.success("Success");
            set({ questionnaireStatus: KYC_Status.Pending });
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },

      bonusInfo: null,

      getBonusInfo: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.bonusInfo();
          set({ bonusInfo: resp.data.data });
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      activateBonus: async (id: number, err: any) => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.bonusActivate(id)({});
          if (resp.error) {
            console.log(resp.error);
            toast.error(err(String(resp.error.code)));
          } else {
            get().getBonusInfo();
            toast.success("Success");
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },

      transactions: [],
      transactionsCount: 0,
      activeActivites: [],
      activeActivitesCount: 0,
      activeTransactions: [],
      activeTransactionsCount: 0,
      activites: [],
      activitesCount: 0,
      getActivities: async (page: number, limit: number) => {
        try {
          const isNewTab = page === 0;
          set({ isLoading: isNewTab });
          const resp = await sircapApi.account.activities({
            params: {
              page,
              limit,
            },
          });

          if (resp.error) {
          } else {
            const [list, count] = resp.data.data;
            if (isNewTab) {
              set({ activites: list, activitesCount: count });
            } else {
              const lastAssets = get().activites;
              set({
                activites: [...lastAssets, ...list],
                activitesCount: count,
              });
            }
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      getTransactions: async (page: number, limit: number) => {
        try {
          const isNewTab = page === 0;
          set({ isLoading: isNewTab });
          const resp = await sircapApi.account.transactions({
            params: {
              page,
              limit,
            },
          });

          if (resp.error) {
          } else {
            const [list, count] = resp.data.data;
            if (isNewTab) {
              set({ transactions: list, transactionsCount: count });
            } else {
              const lastAssets = get().transactions;
              set({
                transactions: [...lastAssets, ...list],
                transactionsCount: count,
              });
            }
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      getActiveTransactions: async (page: number, limit: number) => {
        try {
          const isNewTab = page === 0;
          set({ isLoading: isNewTab });
          const resp = await sircapApi.account.activeTransactions({
            params: {
              page,
              limit,
            },
          });

          if (resp.error) {
          } else {
            const [list, count] = resp.data.data;
            if (isNewTab) {
              set({ activeTransactions: list, activeTransactionsCount: count });
            } else {
              const lastAssets = get().activeTransactions;
              set({
                activeTransactions: [...lastAssets, ...list],
                activeTransactionsCount: count,
              });
            }
          }
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },

      reports: [],
      getReports: async () => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.reportsList();
          set({ reports: resp.data.data.list });
        } catch (error) {
        } finally {
          set({ isLoading: false });
        }
      },
      generateReport: async (dto: GenerateReportDto) => {
        try {
          set({ isLoading: true });
          const resp = await sircapApi.account.generateReport(dto);
          return resp.data.data.fileName;
        } catch (error) {
          return null;
        } finally {
          set({ isLoading: false });
        }
      },

      promoCode: async (value: string) => {
        const resp = await sircapApi.account.promoCode(value)({});
        // console.log(resp);
        return resp;
      },
    }),
    {
      name: "account",
      version: 1,
      merge: (persistedState, currState) => {
        const {
          requestedEmail,
          requestedPhone,
          depositCurrencies,
          selectedDepositCurrency,
          selectedWithdrawCurrency,
        } = persistedState as Partial<AccountState>;

        return {
          ...currState,

          requestedEmail: requestedEmail || "",
          requestedPhone: requestedPhone || "",
          selectedDepositCurrency: selectedDepositCurrency || "",
          selectedWithdrawCurrency: selectedWithdrawCurrency || "",
          depositCurrencies: depositCurrencies?.length ? depositCurrencies : [],
        };
      },
    }
  )
);

export const useDepositInfo = (currency: string, bank: number | null) =>
  useStore(accountStore, (state) =>
    state.depositInfo.find(
      (v) =>
        (currency ? v.currency.code === currency : true) &&
        (bank ? v.info_source.id === bank : true)
    )
  );

export const useWithdrawalInfo = (
  currency: string,
  bank: number | null
  // accountNumber: number | null
) =>
  useStore(accountStore, (state) =>
    state.withdrawInfo.find(
      (v) =>
        (currency ? v.currency.code === currency : true) &&
        (bank ? v.info_source.id === bank : true)
      //  &&
      // (accountNumber ? v.info_source.id === accountNumber : true)
    )
  );

export const useUserKYCStatus = () =>
  useStore(accountStore, (state) => {
    if (sessionStore.getState().entity === EntityType.Individual) {
      return state.kyc?.status;
    } else {
      return state.kyb?.status;
    }
  });
