import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { sircapApi } from "shared/api/sircap";
import { PATH_LIST } from "shared/lib/react-router";
import { AssetType, OperationType } from "static/types";
import numeral from "numeral";

numeral.register("locale", "custom", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "K",
    million: "M",
    billion: "B",
    trillion: "T",
  },
  ordinal: function (number) {
    var b = number % 10;
    return ~~((number % 100) / 10) === 1
      ? "th"
      : b === 1
      ? "st"
      : b === 2
      ? "nd"
      : b === 3
      ? "rd"
      : "th";
  },
  currency: {
    symbol: "$",
  },
});
numeral.locale("custom");

export const b64toBlob = (
  srcData: string,
  contentType = "",
  sliceSize = 512
) => {
  try {
    const base64Data = srcData.split(",")[1];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  } catch (error) {}
};

export const blobToFile = (blob: Blob, filename: string): File => {
  return new File([blob], filename, { type: blob.type });
};

export const useSchema = (Schema: (t: any) => any) => {
  const { i18n, t } = useTranslation("yup");
  const schema = useMemo(() => Schema(t), [i18n.language]);

  return schema;
};

export function formatPrice(x: number, fixed: number = 2) {
  if (!x) return "";

  let formatted = x
    .toFixed(fixed)
    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1,");

  formatted = formatted.replace(/(\.\d*?[1-9])0+$/g, "$1"); // Remove only trailing zeros after a non-zero digit
  formatted = formatted.replace(/\.00$/, ""); // Remove ".00" completely if it ends with it

  return formatted;
}

export function formatMarketCap(x: number) {
  return x ? numeral(x).format("0.0a") : "";
}

export const getAvatarUrl = (file: string) => {
  return `${sircapApi.baseUrl}/uploads/avatar/${file}`;
};

export const getChatFileUrl = (room: number, file: string) => {
  return `${sircapApi.baseUrl}/uploads/chat/${room}/${file}`;
};

export const getContractUrl = (file: string) => {
  return `${sircapApi.baseUrl}/documents/contracts/${file}`;
};

export const getStoryImgUrl = (fileName: any) => {
  return `${sircapApi.baseUrl}/gallery/${fileName}`;
};

export const getAssetIconUrl = (symbol: string) => {
  return `https://financialmodelingprep.com/image-stock/${symbol}.png`;
};

export const showNotification = (text: string) => {
  toast.info(text, {
    className: "notification-modal",
    bodyClassName: "notification-modal-text",
    position: "bottom-center",
    icon: false,
    hideProgressBar: true,
  });
};

export const navigateToAsset = (
  type: number,
  asset_id: any,
  navigate: any,
  operation: string = ""
) => {
  switch (type) {
    case AssetType.Stock:
    case AssetType.Fond:
    case AssetType.ETF:
      navigate(PATH_LIST.stock(asset_id));
      break;

    case AssetType.Currency:
      navigate(PATH_LIST.currency(asset_id));
      break;

    case AssetType.Bond:
    case AssetType.EuroBond:
      navigate(PATH_LIST.bond(asset_id));
      break;

    case AssetType.StructuredProduct:
      navigate(PATH_LIST.strategyInvest(asset_id, operation));
      break;

    default:
      break;
  }
};

export const navigateToAssetByWill = (
  type: number,
  asset_id: any,
  operation: string = ""
) => {
  switch (type) {
    case AssetType.Stock:
    case AssetType.Fond:
    case AssetType.ETF:
      return PATH_LIST.stock(asset_id);

    case AssetType.Currency:
      return PATH_LIST.currency(asset_id);

    case AssetType.Bond:
    case AssetType.EuroBond:
      return PATH_LIST.bond(asset_id);

    case AssetType.StructuredProduct:
      return PATH_LIST.strategyInvest(asset_id, operation);

    default:
      break;
  }
};

export const getCommission = (
  commision: number,
  amount: number,
  operation: string,
  minimal_commission: number,
  with_sirius: boolean
) => {
  if (with_sirius) return [0, 0, 0];

  let totalCommission = +((amount / 100) * commision).toFixed(2);
  if (minimal_commission) {
    totalCommission =
      totalCommission < minimal_commission
        ? minimal_commission
        : totalCommission;
  }

  // let totalAmount: number;
  // if (operation === OperationType.Sell) {
  //   totalAmount = amount - totalCommission;
  // } else {
  //   totalAmount = amount + totalCommission;
  // }
  // return [commision, additional, totalAmount];

  const totalAmount =
    operation === OperationType.Sell ? totalCommission * -1 : totalCommission;

  return [commision, 0, totalAmount];
};

export const getRatePrice = (all_rates: any[], rate: any) => {
  const rateExists = all_rates.find(
    (r) =>
      r.price_asset.code === rate.price_asset &&
      r.trade_asset.code === rate.trade_asset
  );

  let price = null;
  if (rateExists) {
    price = +rateExists.price.toFixed(4);
  } else {
    const rateSwapExists = all_rates.find(
      (r) =>
        r.price_asset.code === rate.trade_asset &&
        r.trade_asset.code === rate.price_asset
    );
    if (rateSwapExists) {
      price = +(1 / rateSwapExists.price).toFixed(4);
    }
  }

  return price;
};

export const convertTo =
  (all_rates: any[], currency: string) =>
  (item: { symbol: string; amount: number }) => {
    const { symbol, amount } = item;

    if (symbol === currency) {
      return amount;
    } else {
      const price = getRatePrice(all_rates, {
        trade_asset: currency,
        price_asset: symbol,
      });
      // console.log(symbol, currency, price);
      if (price) {
        return amount / price;
      } else {
        return 0;
      }
    }
  };

export const getCurrSymbol = (code: string) => {
  const symbols: Record<string, string> = {
    AMD: "֏",
    USD: "$",
    EUR: "€",
    RUB: "₽",
    AED: "د.إ",
    CNY: "¥",
    GBP: "£",
  };

  return symbols[code];
};

export const isBondMatured = (maturity_date: string | null) => {
  if (maturity_date) {
    const format = "YYYY-MM-DD";
    const maturityDate = moment(maturity_date, format);
    if (maturityDate.isBefore(moment().add(5, "day"))) {
      return true;
    }
  }
  return false;
};

export type MultiLangValueType = Record<string, string>;

export const getMLValue = (field: MultiLangValueType, locale = "en") => {
  return field[locale] || field[Object.keys(field)[0]] || "";
};

export const getFromLotsText = (lots: number, t: any) => {
  return `${lots} ${lots === 1 ? t("lot") : t("lots")}`;
};

export const getStrategyImgUrl = (strategy: string, fileName: any) => {
  return `${sircapApi.baseUrl}/gallery/strategy/${strategy}/${fileName}`;
};

export type MultiLangField = Record<string, string>;

// export const getAssetPrice = (
//   assetInfo: IAssetInfo | null,
//   type: "ask" | "bid" | "mid"
// ) => {
//   try {
//     if ([AssetType.ETF, AssetType.Stock, AssetType.Fond].includes(+type)) {
//       const info = assetInfo?.asset;
//       if (info) return info.ask;
//     } else if ([AssetType.Bond, AssetType.EuroBond].includes(+type)) {
//       const info = assetInfo?.asset;
//       if (info) return info.ask;
//     } else {
//       const askPrice = assetInfo?.asset?.ask;
//       if (askPrice) {
//         return +askPrice.toFixed(2);
//       }
//     }
//     return 0;
//   } catch (error) {
//     return 0;
//   }
// };

//! low quality placeholder
// export const createLowQualityPlaceholder = (
//   imageUrl: string,
//   quality: number = 0.1
// ): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.crossOrigin = "Anonymous";
//     img.onload = () => {
//       const canvas = document.createElement("canvas");
//       const ctx = canvas.getContext("2d");
//       if (!ctx) return reject("Canvas context not found");

//       canvas.width = img.width / 10;
//       canvas.height = img.height / 10;

//       ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

//       const dataUrl = canvas.toDataURL("image/jpeg", quality);
//       resolve(dataUrl);
//     };
//     img.onerror = (err) => reject(err);
//     img.src = imageUrl;
//   });
// };