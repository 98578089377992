import React, { FC, useEffect, useState, useCallback } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import ChevronDown from "../../../assets/ChevronDown.png";

import { useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import { PriceTypeModal } from "../components/price-type";
import { AssetType, OperationType, OrderType } from "static/types";
import { dashboardStore } from "entities/dashboard";
import { useStore } from "zustand";
import { marketStore, useTariff } from "entities/market";
import {
  getCommission,
  getRatePrice,
  navigateToAsset,
} from "helpers/functions";
import { formatMoney } from "helpers/money";
import Loading from "widgets/loading";
import Modal from "widgets/auth/modal";
import { useTranslation } from "react-i18next";
import { ResponseCodes } from "shared/api/sircap";
import { toast } from "react-toastify";
import TermsCheckbox from "pages/onboarding/components/terms-checkbox";
import useCanAction from "hooks/useCanAction";

const OrderTypes = {
  [OperationType.Buy]: OrderType.Market,
  [OperationType.Sell]: OrderType.Market,
};

export const InvestPage = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [type, setType] = useState("");
  const { asset_id, operation } = useParams();
  const [confirmDeal, setConfirmDeal] = useState(false);
  const { user_deposit_accounts, user_brokerage_accounts, loadUserAccounts } =
    useStore(dashboardStore);
  const { isLoading, assetInfo, loadAssetInfo, openOrder } =
    useStore(marketStore);
  const currSymbol = assetInfo?.asset?.currency?.symbol || "";
  const { t: tError } = useTranslation("errors");
  const { t } = useTranslation("app");
  const [load, setLoad] = useState(false);
  const [dealError, setDealError] = useState(false);
  const action = useCanAction();

  const symbol = assetInfo?.asset?.symbol || "";
  const currencySymbol = assetInfo?.asset?.currency?.symbol;
  const asset = user_brokerage_accounts?.find(
    (ua) => ua.asset.symbol === symbol
  );
  const annualYield = assetInfo?.asset?.data?.annual_yield;

  useEffect(() => {
    try {
      loadAssetInfo(asset_id);
      loadUserAccounts();
    } catch (error) {
      navigate(-1);
    }
  }, []);

  const getUserAccount = useCallback(() => {
    const curr = assetInfo?.asset?.currency.code;
    const acc = user_deposit_accounts.find((acc) => acc.currency.code === curr);
    return {
      symbol: curr,
      amount: formatMoney(acc?.amount || 0),
    };
  }, [user_deposit_accounts, assetInfo]);

  const getAssetPriceAsk = useCallback(() => {
    try {
      const info = assetInfo?.asset;
      if (info) {
        const { ai, nominal } = info?.data;
        return formatMoney((info.ask / 100) * nominal + ai);
      }
      return 0;
    } catch (error) {
      return 0;
    }
  }, [assetInfo]);

  const getAssetPriceBid = useCallback(() => {
    try {
      const info = assetInfo?.asset;
      if (info) {
        const { ai, nominal } = info?.data;
        return formatMoney((info.bid / 100) * nominal + ai);
      }
      return 0;
    } catch (error) {
      return 0;
    }
  }, [assetInfo]);

  const getAssetPrice = useCallback(() => {
    if (operation === OperationType.Buy) {
      return getAssetPriceAsk();
    } else if (operation === OperationType.Sell) {
      return getAssetPriceBid();
    } else {
      return 0;
    }
  }, [assetInfo, operation]);

  const getTotalQuantity = useCallback((): number => {
    try {
      const price = getAssetPrice();
      const q = amount! / price;
      if (!q) return 0;
      return parseFloat(q.toFixed(4));
    } catch (error) {
      return 0;
    }
  }, [amount, assetInfo]);

  const getAvailableLots = useCallback(() => {
    try {
      const price = getAssetPrice();
      const balance = getUserAccount().amount;
      const [_, __, commision] = getCommission(
        assetInfo?.commission || 0,
        balance,
        OperationType.Buy,
        assetInfo?.minimal_commission || 0,
        false
      );
      const lots = (balance - commision) / (price * 1);
      if (lots === Infinity || lots < 0) return 0;
      return lots;
    } catch (error) {
      return 0;
    }
  }, [assetInfo, getUserAccount]);

  const getTotalAmount = () => {
    try {
      return +(asset!.quantity * asset!.price).toFixed(2);
    } catch (error) {
      return 0;
    }
  };

  const getAmountRange = useCallback(() => {
    try {
      if (operation === OperationType.Buy) {
        const price = getAssetPrice();
        const minAmount = assetInfo?.asset?.data?.minimal_invest;
        const maxAmount = +(getAvailableLots() * price).toFixed(4);
        if (!minAmount || !maxAmount) return [0, 0];
        return [minAmount, maxAmount];
      } else {
        const minAmount = 0;
        const maxAmount = getTotalAmount();
        if (!maxAmount) return [0, 0];
        return [minAmount, maxAmount];
      }
    } catch (error) {
      return [0, 0];
    }
  }, [assetInfo, getAvailableLots, getAssetPrice, getTotalAmount]);

  const getCommissionWrap = useCallback(() => {
    try {
      return getCommission(
        assetInfo?.commission || 0,
        amount!,
        operation!,
        assetInfo?.asset?.minimal_commission || 0,
        false
      );
    } catch (error) {
      return [0, 0, 0];
    }
  }, [assetInfo, amount]);

  const getTotalPriceAndCommission = (): string => {
    try {
      const total = amount;
      if (!total) return "";
      const commission = getCommissionWrap()[2];
      return `${+(total + commission).toFixed(2)}${currSymbol}`;
    } catch (error) {
      return "";
    }
  };

  const getAssetCommission = () => {
    try {
      const [commission, additional, amount] = getCommissionWrap();
      if (!amount) return <></>;

      return (
        <div className="deal-commission">
          {amount > assetInfo!.asset!.minimal_commission ? (
            <p>
              {t("commission")}: {commission}%{" "}
              {additional ? `+ ${additional}$` : ""}
            </p>
          ) : (
            <p>
              {" "}
              {t("commission")}: {additional ? `+ ${additional}$` : ""}
            </p>
          )}
          <span>
            {amount}
            {assetInfo!.asset!.currency.symbol}
          </span>
        </div>
      );
    } catch (error) {
      return <></>;
    }
  };

  const getAssetYield = useCallback(() => {
    try {
      const info = assetInfo?.asset;
      if (info) {
        const { annual_yield } = info?.data;
        const yield_v = formatMoney(annual_yield);

        return (
          <div className="deal-commission">
            <p>
              {t("yield")}: {yield_v}%
            </p>
            {/* <span>
                {amount}
                {assetInfo!.asset!.currency.symbol}
              </span> */}
          </div>
        );
      }
      return <></>;
    } catch (error) {
      return <></>;
    }
  }, [assetInfo]);

  const handleBuy = (pinCode: string) => {
    if (assetInfo?.asset && operation) {
      setLoad(true);
      openOrder(
        assetInfo.asset.id,
        {
          pinCode,
          comments: "",
          asset: assetInfo.asset.id,
          type: OrderTypes[operation as OperationType],
          price: getAssetPrice(),
          quantity: getTotalQuantity(),
          operation: operation!,
          with_sirius: false,
        },
        tError,
        (error?: number) => {
          setLoad(false);
          if (error) {
            if (error === ResponseCodes.WrongPinCode) {
              setDealError(true);
            } else {
              const err = String(error);
              toast.error(tError(err));
            }
          } else {
            toast.success("Success");
            navigate(PATH_LIST.strategy(asset_id!));
          }
        }
      );
    }
  };

  const unavailable = useCallback(() => {
    const totalAmount = parseFloat(getTotalPriceAndCommission());
    const userBalance = getUserAccount().amount as number;
    const [min, max] = getAmountRange();
    if (operation === OperationType.Buy) {
      if (!amount || amount < 0 || !userBalance || !totalAmount || amount < min)
        return true;
      return totalAmount > userBalance;
    } else {
      if (!amount || amount < 0 || !totalAmount || amount < min) return true;
      return amount > max;
    }
  }, [amount, getTotalPriceAndCommission, getUserAccount, getAmountRange]);

  if (isLoading) return <Loading />;

  return (
    <div className="deal-container strategy-invest-deal-container">
      <div className="deal-header">
        <img
          className="go-back-portfolio"
          onClick={() => navigate(-1)}
          src={GoBack}
          alt="Go Back"
        />
        <div>
          {/* <p>{symbol}</p>
          <div>
            <span>
              {getAssetPriceAsk()}
              {currSymbol}
            </span>
            {getAssetDayChange().percent && (
              <span className={getAssetDayChange().isLoss ? "loss" : ""}>
                {getAssetDayChange().percent}
              </span>
            )}
          </div> */}
          <p>{t("invest")}</p>
        </div>
      </div>
      <div className="deal-main">
        <div className="deal-on-account">
          <span className="title-span">{t("account")}</span>
          <div className="deal-on-account-box">
            <div>
              <span>{t("mainAccount")}</span>
              <p>
                {getUserAccount().amount}
                {currSymbol}
              </p>
            </div>
          </div>
        </div>

        {asset && asset.quantity > 0 && (
          <div className="current-investment-amount">
            <label>{t("currentInvestmentAmount")}</label>
            <div>
              <p>
                {getTotalAmount()} {currencySymbol}
              </p>
              {/* <span>
                {annualYield}% {t("annualReturn")}
              </span> */}
            </div>
          </div>
        )}

        <label htmlFor="amount">
          {operation === OperationType.Buy
            ? t("enterAmountToInsvest")
            : t("enterNewAmount")}
          <br />
          {operation === OperationType.Buy && (
            <>
              {t("min")}: {getAmountRange()[0]}
              {currencySymbol} <br />
            </>
          )}
          {getAmountRange()[1] < getAmountRange()[0] ? (
            <div className="bargaining-alert">
              <span>{t("insufficientFunds")}</span>
            </div>
          ) : (
            <>
              {t("max")}: {getAmountRange()[1]}
              {currencySymbol}
            </>
          )}
        </label>
        <div className="price-box">
          <input
            id="amount"
            className="specific-price-input"
            type={"number"}
            value={amount}
            onChange={(e) => {
              // const newValue = e.target.value.replace(/[^0-9.]/g, "");
              // setSpecificPrice(parseFloat(newValue));
              const strValue = String(e.target.value);
              const value = +strValue || "";
              setAmount(value as number);
            }}
            min={getAmountRange()[0]}
            max={getAmountRange()[1]}
            autoFocus={true}
          />
          <span>{currSymbol}</span>
        </div>
        {/* {getAssetYield()} */}
        {getAssetCommission()}
        <TermsCheckbox
          term={t("iAgreeTo")}
          PrivacyPolicy={t("termsConditions")}
          htmlForId="1"
          isChecked={checked}
          onChange={() => setChecked(!checked)}
        />
      </div>

      <div className="btn-wrapper deal-btn-wrapper">
        <Button
          type="submit"
          disabled={unavailable() || isLoading || !checked}
          variant="dark"
          className="costumBtn deal-btn"
          onClick={() => action(() => setConfirmDeal(true))}
        >
          {operation === OperationType.Buy
            ? t("confirmInvestment")
            : t("confirm")}
          <span>{getTotalPriceAndCommission()}</span>
        </Button>
      </div>

      {confirmDeal === true && (
        <Modal
          customModal="modalLogOut"
          onClick={handleBuy}
          onHide={() => setConfirmDeal(false)}
          title={t("confirmDeal")}
          text={t("toCompleteDeal")}
          btnText={t("Confirm")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal={true}
          isLoading={load}
          dealError={dealError}
        />
      )}
    </div>
  );
};
