import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ResolutionString,
  TimeFramePeriodBack,
  TimeFrameType,
  widget,
} from "charting_library";
import ChartRangeBtn from "pages/app/components/chart-rang-btn";
import Datafeed from "./datafeed";

import JapaneseCandlesticks from "../../../assets/JapaneseCandlesticks.svg";
import LineGraph from "../../../assets/LineGraph.svg";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import { useTranslation } from "react-i18next";

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
}

type PropsType = {
  onlyLineChart?: boolean;
};

export const ChartView: React.FC<PropsType> = ({ onlyLineChart }) => {
  const [activeRange, setActiveRange] = useState("1M");
  const [tvw, setTVWidget] = useState<IChartingLibraryWidget>();
  const [isLineChart, setIsLineChart] = useState(false);
  const { t } = useTranslation("app");

  const { assetInfo, assetChart } = useStore(marketStore);

  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    try {
      if (!assetChart || !assetChart?.chart || !assetChart?.chart?.length) {
        return;
      }

      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol: "AAPL",
        // tslint:disable-next-line:no-any
        // datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
        //   "https://demo_feed.tradingview.com"
        // ),
        datafeed: Datafeed,
        interval: "1D" as ResolutionString,
        container: chartContainerRef.current,
        library_path: "/charting_library/",

        locale: "en",
        disabled_features: [
          "header_widget",
          "legend_widget",
          "left_toolbar",
          "go_to_date",
          "context_menus", // mouse right click
          "main_series_scale_menu", //  - ( indicator setings $, %, ... )
          "use_localstorage_for_settings", // saving all sets local (for ex chart type)
          "save_chart_properties_to_local_storage",
          "popup_hints",

          //todo --- < MAY BE NEEDED >
          "symbol_search_hot_key",
          "border_around_the_chart",
          "items_favoriting",
          "scales_date_format",
          "timezone_menu",
          "source_selection_markers",
          "show_chart_property_page",
          "timeframes_toolbar", //footer date-time
          // "control_bar", - ( - ; + ; undo )
          // "popup_hints", notifications from bottom
          // "pane_context_menu",
          // "show_right_widgets_panel_by_default",
          // "display_market_status",
          // "auto_enable_symbol_labels",
          // "cropped_tick_marks",
          // "chart_crosshair_menu",  terminal?????
          // "remove_library_container_border",
          // "property_pages",
          // "show_chart_property_page",
          // "dont_show_boolean_study_arguments",

          // {legend (top left)
          // "edit_buttons_in_legend",
          // "legend_context_menu",
          // "hide_main_series_symbol_from_indicator_legend",}

          //  { "header_chart_type",
          //   "header_compare",
          //   "header_fullscreen_button",
          //   "header_indicators",
          //   "header_resolutions",
          //   "show_interval_dialog_on_key_press",
          //   "header_screenshot",
          //   "header_settings",
          //   "header_symbol_search",
          //   "header_undo_redo",
          //   "header_quick_search",}

          //todo --- < MAY BE NEEDED />
        ],
        enabled_features: [
          "study_templates",
          // "seconds_resolution",
          // "show_symbol_logos",
          // "show_symbol_logos",
          // "end_of_period_timescale_marks",
        ],

        // charts_storage_url: "https://saveload.tradingview.com",
        // charts_storage_api_version: "1.1",
        // client_id: "tradingview.com",
        // user_id: "public_user_id",
        fullscreen: false,
        autosize: true,
        studies_overrides: {},

        // custom_font_family: "SpaceGrotesk",
        // custom_css_url: "./style.css",
        // theme: "dark",
        // toolbar_bg: "red",

        overrides: {
          // "mainSeriesProperties.style": 2,
          // "paneProperties.background": "snow",
        },
      };

      const tvWidget = new widget(widgetOptions);
      setTVWidget(tvWidget);

      tvWidget.onChartReady(() => {
        try {
          tvWidget.activeChart().setChartType(onlyLineChart ? 3 : 1);
        } catch (error) {}
      });

      return () => {
        tvWidget.remove();
      };
    } catch (error) {}
  }, [assetChart]);

  const setTimeFrame = (res: string, frame: string) => {
    setActiveRange(res);
    // tvw!.activeChart().setResolution(res as ResolutionString);
    tvw!.activeChart().setTimeFrame({
      val: {
        type: "period-back" as TimeFrameType,
        value: res,
      } as TimeFramePeriodBack,
      res: frame as ResolutionString,
    });
  };

  if (
    !assetChart ||
    !assetChart?.chart?.length ||
    !assetInfo ||
    assetInfo?.asset?.symbol !== assetChart?.asset?.symbol
  ) {
    return <></>;
  }

  return (
    <div className="new-chart">
      {tvw && (
        <div className="chart__header">
          <div className="range-btns">
            <ChartRangeBtn
              active={activeRange === "1D"}
              onClick={() => setTimeFrame("1D", "1")}
              range={"1D"}
            />
            <ChartRangeBtn
              active={activeRange === "1W"}
              onClick={() => setTimeFrame("1W", "1D")}
              range={"1W"}
            />
            <ChartRangeBtn
              active={activeRange === "1M"}
              onClick={() => setTimeFrame("1M", "1D")}
              range={"1M"}
            />
            <ChartRangeBtn
              active={activeRange === "12M"}
              onClick={() => setTimeFrame("12M", "1D")}
              range={"1Y"}
            />
            <ChartRangeBtn
              active={activeRange === "60M"}
              onClick={() => setTimeFrame("60M", "1W")}
              range={"5Y"}
            />

            <ChartRangeBtn
              active={activeRange === "240M"}
              onClick={() => setTimeFrame("240M", "1W")}
              range={"All"}
            />
          </div>

          {tvw && !onlyLineChart && (
            <button
              className="switch-chart-type"
              onClick={() => {
                setIsLineChart(!isLineChart);
                tvw.activeChart().setChartType(isLineChart ? 1 : 3);
                // const chartType = tvw.activeChart().chartType();
                // console.log(chartType, tvw.activeChart().getAllStudies());
                // if (chartType === 3) {
                //   const studies = tvw.activeChart().getAllStudies();
                //   if (!studies.length) {
                //     tvw!.activeChart().createStudy("Volume", true, true);
                //   }
                // } else {
                //   tvw!.activeChart().removeAllStudies();
                // }
              }}
            >
              <img
                src={isLineChart ? JapaneseCandlesticks : LineGraph}
                alt="chart-type-icon"
              />
            </button>
          )}
        </div>
      )}

      {/* <iframe
        src="https://siriuscapital.am"
        width={"100%"}
        height={400}
        sandbox="allow-scripts allow-same-origin allow-presentation"
      ></iframe> */}

      <div ref={chartContainerRef} className="chart-view" />

      <span className="tradingView-license">
        {t("chartProvided")}{" "}
        <a target="_blank" href="https://www.tradingview.com/symbols/BTCUSD/">
          {t("tVCart")}
        </a>
      </span>
    </div>
  );
};
