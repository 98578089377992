import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import ModalButton from "widgets/auth/components/modal-btn";

const AppUpdateModal: React.FC<{
  canSkip: boolean;
  onHide: any;
  onUpdate: any;
}> = ({ canSkip, onHide, onUpdate }) => {
  const { t, i18n } = useTranslation("app");

  return (
    <div className="outlet-app-update-modal">
      <div className="app-update-modal">
        <p>{t("updateTitle")}</p>
        <span>{t("updateMessage")}</span>
        <div>
          {canSkip && (
            <ModalButton customClass="app-update-modal-btn" onClick={onHide}>
              {t("skipBtn")}
            </ModalButton>
          )}
          <ModalButton
            customClass={canSkip ? undefined : "app-update-modal-only-btn"}
            onClick={onUpdate}
          >
            {t("updateBtn")}
          </ModalButton>
        </div>
      </div>
    </div>
  );
};

export default AppUpdateModal;
