import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { PersonalInfoEditCard } from "../components/personal-info-edit-card";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import CitizenshipField from "../components/citizenship-field";
import { useEffect, useState } from "react";
import Armenia from "../../../assets/Armenia.svg";
import Other from "../../../assets/Language.svg";
import VerificationStepsHeader from "pages/onboarding/components/verification-steps-header";
import { accountStore, KYC_Step } from "entities/account";
import { toast } from "react-toastify";
import { EntityType } from "static/types";
import { sessionStore } from "entities/session/model/sessionModel";
import Button from "shared/ui/button";
import TermsCheckbox from "pages/onboarding/components/terms-checkbox";

export const IdentityVerification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("app");
  const { t: o } = useTranslation("onboarding-auth");
  const { t: err } = useTranslation("errors");
  const {
    startKyc,
    startKyb,
    synckyc,
    synckyb,
    isLoading,
    stepKyc,
    isLoading: loading,
    kyc,
  } = useStore(accountStore);
  const { entity } = useStore(sessionStore);
  const [country, setCountry] = useState("");
  const [verificationNumber, setVerificationNumber] = useState("");
  const [isUsaCitizen, setIsUsaCitizen] = useState<boolean | undefined>(
    kyc?.has_american_citizenship
  );

  const [showInvalidMessage, setShowInvalidMessage] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (verificationNumber.length > 0 && verificationNumber.length !== 9) {
        setShowInvalidMessage(true);
      } else {
        setShowInvalidMessage(false);
      }
    }, 2000);

    return () => {
      clearTimeout(timeout);
      setShowInvalidMessage(false);
    };
  }, [verificationNumber]);

  const onSubmit = async () => {
    const resp = await stepKyc({
      step: KYC_Step.Passport,
      has_american_citizenship: isUsaCitizen,
      document_number: verificationNumber,
    });

    if (resp.error) {
      toast.error(err(String(resp.error.code)));
    } else {
      const kyc = resp.data.data;
      console.log(kyc);
      if (kyc) {
        synckyc(kyc);
        navigate(PATH_LIST.selfieWithDocumentPage);
      }
    }
  };

  return (
    <div className="citizenship-verification">
      <VerificationStepsHeader percent={50} step={1} total={2} />

      <div className="citizenship-verification-main">
        <p className="citizenship-title">{t("enterIDorpassport")}</p>

        <div className="request-verification-number">
          <input
            type="text"
            maxLength={9}
            placeholder="ID/passport number"
            value={verificationNumber}
            onChange={(e) =>
              setVerificationNumber(
                e.target.value.toUpperCase().replace(/\s/g, "")
              )
            }
          />
          {showInvalidMessage && <span>{t("invalidIDorpassport")}</span>}
        </div>

        <div className="has-usa-checkbox">
          <p>{o("hasUSACitizenship")}</p>

          <div className="checkbox-wrapper">
            <TermsCheckbox
              term={o("yes")}
              htmlForId="1"
              isChecked={isUsaCitizen == true}
              onChange={() => setIsUsaCitizen(true)}
            />
            <TermsCheckbox
              term={o("no")}
              htmlForId="2"
              isChecked={isUsaCitizen == false}
              onChange={() => setIsUsaCitizen(false)}
            />
          </div>
        </div>

        <div className="btn-wrapper">
          <Button
            className="request-verification-button"
            type="submit"
            disabled={verificationNumber.length !== 9}
            variant="dark"
            onClick={() => onSubmit()}
          >
            {t("Request")}
          </Button>
        </div>
      </div>
    </div>
  );
};
