import React, { useEffect, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "shared/ui/form-field";
import { onboardModel } from "entities/onboard";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useSchema } from "helpers/functions";
import { useTranslation } from "react-i18next";
import GoBack from "../../../assets/GoBackApp.svg";
import VerificationStepsHeader from "../components/verification-steps-header";
import { accountStore, KYC_Step } from "entities/account";

let LoginSchema = (t: any) =>
  Yup.object().shape({
    text: Yup.string().required(t("addressRequired")),
  });

export const PersonalAddressPage = () => {
  const schema = useSchema(LoginSchema);
  const navigate = useNavigate();
  const { syncUser, personalAddress, setSome } = useStore(
    onboardModel.onboardStore
  );
  const { stepKyc, isLoading: loading, kyc, synckyc } = useStore(accountStore);
  const [isSubmit, setIsSubmit] = useState(false);
  const { t } = useTranslation("onboarding-auth");
  const { t: a } = useTranslation("app");

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.utilityBillPhotoPage);

    return () => setLink("");
  }, []);

  const onRegister = async (address: string) => {
    setIsSubmit(true);
    const resp = await stepKyc({
      step: KYC_Step.Address,
      personal_address: address,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const kyc = resp.data.data;
      synckyc(kyc);

      navigate(PATH_LIST.account, {
        state: { verificationRequestModal: true },
      });
    }
    setIsSubmit(false);
  };

  return (
    <div className="proof-of-address">
      <VerificationStepsHeader percent={100} step={7} />

      <div className="proof-of-address-main">
        <p className="register-title">{t("personalAddressQuestion")}</p>
        <span className="register-subtitle">
          {t("searchCurrentResidentialAddress")}
        </span>

        <Formik
          initialValues={{ text: kyc?.personal_address || "" }}
          validationSchema={schema}
          onSubmit={(values) => {
            onRegister(values.text);
          }}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty } = formik;
            return (
              <Form>
                <FormField
                  id="text"
                  label={t("personalAddress")}
                  type="text"
                  placeholder={t("enterPersonalAddress")}
                  isInvalid={!!(errors.text && touched.text)}
                />

                <div className="btn-wrapper">
                  <Button
                    type="submit"
                    className="registerNameBtn"
                    variant={"dark"}
                    disabled={!isValid || isSubmit}
                  >
                    {t("continue")}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
