import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import GoBackButton from "shared/ui/back-button";
import Button from "shared/ui/button";
import "./style.scss";
import { ReactCountryDropdown } from "widgets/country-select";
import { useStore } from "zustand";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import axios from "axios";
import TermsCheckbox from "../components/terms-checkbox";
import VerificationStepsHeader from "../components/verification-steps-header";
import { accountStore, KYC_Step } from "entities/account";
import { toast } from "react-toastify";

export const RegisterCitizenshipPage = () => {
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");
  const { t: err } = useTranslation("errors");
  const { stepKyc, isLoading: loading, kyc, synckyc } = useStore(accountStore);
  const [isLoading, setIsLoading] = useState(false);
  const [isUsaCitizen, setIsUsaCitizen] = useState<boolean | undefined>(
    kyc?.has_american_citizenship
  );
  const [countryByIp, setCountryByIp] = useState("ARM");
  const [selectedCountry, setSelectedCountry] = useState<string>(
    kyc?.residence || countryByIp
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const resp = await axios.get("https://ipapi.co/json/", {
          timeout: 1000,
        });
        if (resp.data) {
          const { country_code_iso3 } = resp.data;
          setCountryByIp(country_code_iso3);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const onSubmit = async () => {
    const resp = await stepKyc({
      step: KYC_Step.Residence,
      residence: selectedCountry,
      has_american_citizenship: isUsaCitizen,
    });

    if (resp.error) {
      toast.error(err(String(resp.error.code)));
    } else {
      const kyc = resp.data.data;
      console.log(kyc);
      if (kyc) {
        synckyc(kyc);
        navigate(PATH_LIST.chooseDocType);
      }
    }
  };

  return (
    <div className="register-citizenship">
      <VerificationStepsHeader percent={42.75} step={3} />

      <div className="register-citizenship-main">
        <p className="register-title">{t("whatsYourCitizenship")}</p>

        {!isLoading ? (
          <ReactCountryDropdown
            onSelect={(c: string) => setSelectedCountry(c)}
            countryCode={selectedCountry}
            placeholder="select"
            labelType="full"
            className="country-select"
          />
        ) : (
          <p>{t("loading")}...</p>
        )}

        <div className="has-usa-checkbox">
          <p>{t("hasUSACitizenship")}</p>

          <div className="checkbox-wrapper">
            <TermsCheckbox
              term={t("yes")}
              htmlForId="1"
              isChecked={isUsaCitizen == true}
              onChange={() => setIsUsaCitizen(true)}
            />
            <TermsCheckbox
              term={t("no")}
              htmlForId="2"
              isChecked={isUsaCitizen == false}
              onChange={() => setIsUsaCitizen(false)}
            />
          </div>
        </div>
        <div className="btn-wrapper">
          <Button
            disabled={
              !selectedCountry || isLoading || isUsaCitizen == undefined
            }
            type="submit"
            onClick={onSubmit}
            className="registerNameBtn"
            variant={"dark"}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};
