import React, { useEffect, useRef, useState } from "react";
import { AssetType } from "static/types";
import "./style.scss";
import AssetIcon from "../asset-icon";
import HelpCircle from "../../../../assets/HelpCircleWTB.svg";
import { formatMoney, getAvgValue } from "helpers/money";
import ExchangeStatusBtn from "widgets/market/exchange-status-btn";
import useExchangeTimeFrame from "hooks/useExchangeTimeFrame";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { ioRTStore } from "services/rt.socket";
import { useAssetVolatility } from "hooks/useAssetVolatility";

type PropType = {
  asset: any;
  showGains?: boolean;
  gains?: any;
  gainsPercent?: any;
  supportingText?: string;
  percentPerAnnum?: string;
  onClick?: () => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  about?: boolean;
  onAboutClick?: () => void;
};

export const MarketAsset: React.FC<PropType> = ({
  asset,
  showGains = false,
  gains,
  gainsPercent,
  supportingText,
  percentPerAnnum,
  onClick,
  onMouseDown,
  about,
  onAboutClick,
}) => {
  const { type, bid, ask, name, symbol, currency, image } = asset;
  const isCurrency = type === AssetType.Currency;
  const [isStock, setIsStock] = useState(false);
  const [price, setPrice] = useState<number>(0);
  const exchange = useExchangeTimeFrame(asset?.exchange);
  const { t } = useTranslation("app");

  const { connected, list, subscribe, unsubscribe } = useStore(ioRTStore);

  const exchangeCode = exchange?.exchange_code;
  useEffect(() => {
    const rtPrice = list[`${exchangeCode}~${symbol}`];
    if (rtPrice) setPrice(rtPrice.ask);
  }, [exchangeCode, list]);

  const isExchangeActive = !!exchange?.isActive;

  useEffect(() => {
    if ([AssetType.ETF, AssetType.Stock, AssetType.Fond].includes(type)) {
      setPrice(ask);
      setIsStock(true);
    } else if (type === AssetType.Currency) {
      setPrice(formatMoney((ask + bid) / 2));
    } else if ([AssetType.Bond, AssetType.EuroBond].includes(type)) {
      setPrice(formatMoney(getAvgValue(ask, bid)));
    } else {
      setPrice(ask);
    }
  }, []);

  useEffect(() => {
    if (
      !connected ||
      !exchangeCode ||
      !symbol ||
      !isExchangeActive ||
      ![AssetType.Stock, AssetType.ETF].includes(type)
    ) {
      return;
    }

    // if (exchangeCode !== "MOEX") return;

    const ticker = `${exchangeCode}~${symbol}`;
    subscribe(ticker);

    return () => {
      unsubscribe(ticker);
    };
  }, [symbol, type, exchangeCode, connected]);

  const { changedPart, unchangedPart, volatilityElementRef } =
    useAssetVolatility(price);

  // todo | add if needed |
  // const {
  //   changedPart: bidChangedPart,
  //   unchangedPart: bidUnchangedPart,
  //   volatilityElementRef: bidVolatilityElementRef,
  // } = useAssetVolatility(bid);

  // const {
  //   changedPart: askChangedPart,
  //   unchangedPart: askUnchangedPart,
  //   volatilityElementRef: askVolatilityElementRef,
  // } = useAssetVolatility(ask);

  return (
    <div onClick={onClick} onMouseDown={onMouseDown} className="market-asset">
      <div className="asset-info">
        <AssetIcon asset={asset} imageUrl={image} />
        <div>
          <>
            {isCurrency ? (
              <p>
                {symbol} / {currency.code}
              </p>
            ) : (
              <div
                className={
                  [AssetType.ETF, AssetType.Stock, AssetType.Fond].includes(
                    type
                  )
                    ? "asset-stock-field"
                    : undefined
                }
              >
                <p>{symbol} </p>
                {isStock && exchange && (
                  <ExchangeStatusBtn assetField status={exchange.status} />
                )}
              </div>
            )}
          </>
          <span className="asset-info-span">
            {isCurrency ? (
              name
            ) : isStock ? (
              <>
                {isStock && exchange ? exchange.exchange_code + " | " : ""}
                {name}
              </>
            ) : (
              name
            )}
          </span>
        </div>
      </div>

      <div className="price-gains-wrapper">
        <div className="price-gains" style={{ rowGap: gains ? "4px" : 0 }}>
          {isCurrency ? (
            <div className="price-gains-currency">
              <p>
                {+bid.toFixed(4)} / {+ask.toFixed(4)} {currency.symbol}
              </p>
            </div>
          ) : [AssetType.Bond, AssetType.EuroBond].includes(type) ? (
            <p>
              {formatMoney(
                getAvgValue(asset.data.ask_yield, asset.data.bid_yield)
              )}
              %{" | "}
              {price}
            </p>
          ) : (
            <p>
              {/* {price} {percentPerAnnum ? "%" : currency.symbol} */}

              {/* ....... */}
              {/* {number}  */}
              <span style={{ color: "#2b2f38" }}>{unchangedPart}</span>
              <span id="volatility" ref={volatilityElementRef}>
                {changedPart}
              </span>
              {/* ....... */}
              {percentPerAnnum ? "%" : currency.symbol}
            </p>
          )}
          {showGains && (
            <div
              className={`${gainsPercent && gainsPercent < 0 ? "loss" : ""}`}
            >
              {percentPerAnnum ? (
                <span>
                  {percentPerAnnum} % {t("perAnnum")}
                </span>
              ) : isCurrency ? (
                <>
                  <span>{gains}</span>
                  <span className="gains-percent">{gainsPercent}%</span>
                </>
              ) : (
                <>
                  {gains && gainsPercent ? (
                    <>
                      <span>{+gains.toFixed(2)}$</span>
                      <span className="gains-percent">
                        {+gainsPercent.toFixed(2)}%
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {about && (
          <img
            onClick={(e) => {
              e.stopPropagation();
              onAboutClick && onAboutClick();
            }}
            className="about-asset"
            src={HelpCircle}
            alt="Help"
          />
        )}
      </div>
    </div>
  );
};
